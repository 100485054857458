<template>
  <div>
    <v-btn class="ma-2" color="orange darken-2" @click="$store.dispatch('backRouter')" dark>
      <v-icon dark left>mdi-arrow-left</v-icon>Back
    </v-btn>
    <json-viewer name="df" :value="jsonData" :expand-depth="5" theme="my-awesome-json-theme" sort></json-viewer>
  </div>
</template>
<script>
export default {
  data: () => ({
    jsonData: {}
  }),
  async mounted() {
    if (this.$route.params.id) {
      await this.$store.dispatch("rooms/getRoom", this.$route.params.id);
      this.jsonData = this.$store.state.rooms.room;
    }
  },
  async created() {}
};
</script>
